import { j as a } from "./jsx-runtime-B6kdoens.js";
import { B as i } from "./Button-BrPm3sL-.js";
import { D as n } from "./Dialog.component-9plNKAVm.js";
import { I as l } from "./Icon-DEu72w0a.js";
import { S as m } from "./Stack-C1p7gIIp.js";
import { T as s } from "./Text-DyXxCJgw.js";
import { c as r } from "./utils-CJ9afRe1.js";
const d = {
  info: "BigExclamation",
  danger: "BigExclamationDestructive",
  warning: "BigExclamationWarning"
}, v = ({
  cancelButtonText: t = "Cancel",
  confirmButtonText: c = "Confirm",
  variant: o = "info",
  ...e
}) => /* @__PURE__ */ a.jsx(
  n,
  {
    onOpenChange: e.onOpenChange,
    open: e.open,
    children: /* @__PURE__ */ a.jsxs(
      n.Content,
      {
        withoutPortal: e.withoutPortal,
        overlayClassName: `${e.overlayClassName} z-full-screen`,
        "aria-label": e.ariaLabel,
        className: r("pt-8 pb-5 max-w-[95%] sm:max-w-96 rounded relative z-full-screen", e.className),
        children: [
          e.onCloseButtonClick && /* @__PURE__ */ a.jsx(
            i,
            {
              onClick: e.onCloseButtonClick,
              variant: "secondary",
              size: "sm",
              circle: !0,
              className: "absolute top-0 right-0 p-0 mr-2 mt-2",
              children: /* @__PURE__ */ a.jsx(l, { name: "Cancel", width: 20, height: 20, strokeWidth: 2 })
            }
          ),
          /* @__PURE__ */ a.jsxs(n.Header, { className: "px-10 flex-1 items-center justify-center", children: [
            !e.icon && /* @__PURE__ */ a.jsx(
              l,
              {
                name: d[o],
                className: "text-black-10"
              }
            ),
            e.icon,
            /* @__PURE__ */ a.jsxs(m, { space: "sm", className: "pt-8", children: [
              e.title && /* @__PURE__ */ a.jsx(
                s,
                {
                  center: !0,
                  weight: "medium",
                  as: "div",
                  children: e.title
                }
              ),
              /* @__PURE__ */ a.jsx(
                s,
                {
                  center: !0,
                  as: "div",
                  children: e.message
                }
              )
            ] })
          ] }),
          /* @__PURE__ */ a.jsxs(n.Footer, { className: r("flex sm:justify-center justify-center gap-6", {
            "flex-row-reverse sm:flex-row-reverse": e.reversActions,
            "flex-row sm:flex-row": !e.reversActions
          }), hideDivider: !0, children: [
            !e.noCancel && /* @__PURE__ */ a.jsx(
              n.Close,
              {
                className: "min-w-32",
                asChild: !0,
                children: /* @__PURE__ */ a.jsx(i, { variant: "outline", children: t })
              }
            ),
            e.onCancel && /* @__PURE__ */ a.jsx(
              i,
              {
                loading: e.loading,
                onClick: e.onCancel,
                variant: "outline",
                className: "min-w-32",
                children: t
              }
            ),
            /* @__PURE__ */ a.jsx(n.Close, { asChild: !0, children: /* @__PURE__ */ a.jsx(
              i,
              {
                className: "min-w-32",
                loading: e.loading,
                onClick: e.onConfirm,
                children: c
              }
            ) })
          ] }),
          e.footer
        ]
      }
    )
  }
);
export {
  v as A
};
