import { j as o } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as s } from "react";
import "./Accordion-D1xtR5eX.js";
import "./Avatar-95vFNu6x.js";
import "./Badge-C0bj4DFU.js";
import "./Button-BrPm3sL-.js";
import "./DropdownMenu-DhgMH6em.js";
import "./Icon-DEu72w0a.js";
import "./Input-BawcWKCk.js";
import "./Label-enmFkL7s.js";
import { T as p } from "./Text-DyXxCJgw.js";
import "./Tooltip-Dy7rqQaX.js";
import "./Stack-C1p7gIIp.js";
import "./Switch-CsWZTc7q.js";
import "./Toast-CNCfI-RS.js";
import "./Toggle-Cn0qt44_.js";
import { H as f } from "./HighlightText.component-BZNRT9Y9.js";
const g = (i) => {
  var t, e, m, r, a, n, l;
  return i ? (t = i.givenName) != null && t.trim() && ((e = i.familyName) != null && e.trim()) ? `${i.givenName} ${i.familyName}` : (m = i.givenName) != null && m.trim() ? i.givenName : (r = i.familyName) != null && r.trim() ? i.familyName : "name" in i && ((a = i.name) != null && a.trim()) ? i.name : "email" in i && ((n = i.email) != null && n.trim()) ? i.email : "emailAddress" in i && ((l = i.emailAddress) != null && l.trim()) ? i.emailAddress : "" : "";
}, D = s((i, t) => {
  const { user: e, isMe: m, highlight: r = "", ...a } = i, n = m && e.givenName ? String(e.givenName) : g(e);
  return /* @__PURE__ */ o.jsxs(p, { as: "div", ref: t, ...a, children: [
    /* @__PURE__ */ o.jsx(f, { text: n, highlightText: r }),
    " ",
    m && /* @__PURE__ */ o.jsx(p, { as: "span", size: "inherit", variant: "secondary", children: "(Me)" })
  ] });
});
export {
  D as U
};
