import { j as r } from "./jsx-runtime-B6kdoens.js";
import "./Accordion-D1xtR5eX.js";
import "./Avatar-95vFNu6x.js";
import "./Badge-C0bj4DFU.js";
import "./Button-BrPm3sL-.js";
import "./DropdownMenu-DhgMH6em.js";
import "./Icon-DEu72w0a.js";
import "./Input-BawcWKCk.js";
import "./Label-enmFkL7s.js";
import { T as i } from "./Text-DyXxCJgw.js";
import "./Tooltip-Dy7rqQaX.js";
import { c as e } from "./utils-CJ9afRe1.js";
import "./Stack-C1p7gIIp.js";
import "./Switch-CsWZTc7q.js";
import "./Toast-CNCfI-RS.js";
import "react";
import "./Toggle-Cn0qt44_.js";
const v = (t) => /* @__PURE__ */ r.jsxs(
  "div",
  {
    className: e("w-full max-w-fit rounded-md bg-white p-6 gap-4 flex flex-col items-center justify-center", t.className),
    children: [
      /* @__PURE__ */ r.jsx(i, { children: t.title }),
      /* @__PURE__ */ r.jsx("div", { className: "w-6 h-6 aspect-square rounded-full z-0 border-2 border-blue-100 border-t-transparent animate-spin" }),
      /* @__PURE__ */ r.jsx(i, { variant: "secondary", size: "xs", children: t.hint })
    ]
  }
);
export {
  v as L
};
